import React from "react"

export const useTestimonialsData = () => {
  const data = [
    {
      client: "Katarzyna Z.",
      company: "Klient prywatny",
      testimony:
        "Zdecydowałam się na współpracę z Klimalab i jestem bardzo zadowolona z wykonanej usługi. Klimatyzacja została zamontowana szybko i sprawnie. Z pełnym przekonaniem mogę polecić tę firmę.",
    },
    {
      client: "Łukasz M.",
      company: "Klient prywatny",
      testimony:
        "Decydując się na założenie klimatyzacji w moim domu, nie wiedziałem za wiele o tej usłudze. Pracownik Klimalab przygotował dla mnie projekt i darmową wycenę, dzięki czemu mogłem w pełni zapoznać się z ofertą. Współpraca z Klimalab była przyjemnością i najlepszą decyzją.",
    },
    {
      client: "Mariusz T.",
      company: "Właściciel InterVi",
      testimony:
        "Klimalab było drugą firmą zajmującą się montażem klimatyzacji, z którą miałem okazję współpracować. Po wcześniejszych doświadczeniach zdecydowanie mogę polecić firmę. Klimalab to profesjonaliści z odpowiednim nastawieniem i podejściem do klienta. Bardzo miło wspominam współpracę z nimi i na pewno mogę polecić firmę innym.",
    },
    {
      client: "Angelika S.",
      company: "Właściciel AS Dietetyk",
      testimony:
        "Chcę, aby moi klienci byli zadowoleni i czuli się komfortowo w moim salonie, dlatego zdecydowałam się na montaż klimatyzacji. Z polecenia wybrałam Klimalab i był to najlepszy wybór. Firma zaproponowała mi ciekawe i nowoczesne rozwiązania, które idealnie sprawdzają się na większej powierzchni, a współpraca z nimi była czystą przyjemnością.",
    },
    {
      client: "Tomasz P.",
      company: "Klient prywatny",
      testimony:
        "Od kilku lat miałem problem z klimatyzacją zamontowaną w moim domu. Dopiero Klimalab rozwiązało mój problem. Firma przygotowała dla mnie darmową wycenę projektu, zamontowała nową klimatyzację, która działa o wiele lepiej. Jestem zadowolony z usługi i cieszę się, że trafiłem na Klimalab!",
    },
    {
      client: "Justyna M.",
      company: "Klient prywatny",
      testimony:
        "Specjaliści z Klimalab to profesjonaliści, którzy potrafią dopasować swoją ofertę do potrzeb klienta. Zaproponowali mi nowoczesną klimatyzację, która idealnie wpasowuje się w wystrój mojego mieszkania oraz spełnia swoje zadanie. Nawet w najbardziej upalne dni cieszę się komfortem w swoim domu.",
    },
    // Dla potomnych, pdw chlopaki!
    // {
    //   client: "Pomidor",
    //   company: "Gdzieś w Anglii",
    //   testimony:
    //     "Popek mi żadnych pieniedzy nie dał. Nikt mnie do lasu nie brał. Pokażcie papiery!",
    // },
    // {
    //   client: "Popek Monster",
    //   company: "Gang Albanii sp. z o.o.",
    //   testimony: "Superańsko. Jestem zadowolony",
    // },
    // {
    //   client: "Jarek Mergner",
    //   company: "Chłopaki do wzięcia",
    //   testimony:
    //     "No ale fajnie tam było, pobawiłem się co i jak i jestem zadowolony no... no.",
    // },
    // {
    //   client: "żurom",
    //   company: "Stop pomówieniom",
    //   testimony:
    //     "Pozdrawiam dobrych chłopaków. Trzymajcie sie wariaty. PDW! Kiedyś będzie dobrze!",
    // },
  ]
  return data
}
