import styled, { css } from "styled-components"
import { OverflowWrapper } from "shared/Styles/Wrappers"
import backgroundImg from "../../../assets/images/About/guybg.png"

export const StyledOverflowWrapper = styled(OverflowWrapper)`
  background-color: #fafafa;
  position: relative;
  background: url(${backgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
`

export const SectionContainer = styled.div`
  padding-top: 110px;
  padding-bottom: 260px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    padding-top: 100px;
    padding-bottom: 120px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    padding-top: 30px;
    padding-bottom: 50px;
  }
`

export const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;
  background-color: white;
  box-sizing: border-box;
  padding: 50px;
  padding-top: 80px;
  box-shadow: 10px 5px 25px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopStandard}) {
    max-width: 320px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    margin: 20px;
    min-width: 220px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    margin-bottom: 70px;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -24px;
    height: 24px;
    left: 0;
    width: 100%;
    background: ${props => props.theme.color.secondary};
    transform: scaleX(0);
    transition: transform 0.3s ease-in;
    transform-origin: left;
  }
  &:hover::after {
    transform: scaleX(1);
  }
`
const margin = css`
  margin-bottom: 25px;
`

export const IconContainer = styled.div`
  position: absolute;
  top: -40px;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopStandard}) {
    svg {
      max-width: 80px;
      max-height: 80px;
    }
  }
`
export const TitleContainer = styled.div`
  ${margin}
  text-align: center;
`
