import React from "react"
import {
  QualityWrapper,
  BlueBackground,
  ContentWrapper,
  TextWrapper,
  ImageWrapper
} from "./styles"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { MediumHeader, Paragraph } from "components/Typography"

export const Quality = () => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/About/shutterstock_chlop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 680, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  
  return (
    <QualityWrapper>
      <BlueBackground />
      <ContentWrapper>
        <ImageWrapper>
          <Img fluid={image.file.childImageSharp.fluid} />
        </ImageWrapper>
        <TextWrapper>
          <MediumHeader>Stawiamy na jakość</MediumHeader>
          <Paragraph>
          Naszym klientom proponujemy profesjonalny sprzęt, który będzie służył latami. W&nbsp;naszej ofercie znajdziesz najlepsze urządzenia, w&nbsp;których stosowane są&nbsp;najnowsze technologie.
          </Paragraph>
          <MediumHeader>Zaufali nam</MediumHeader>
          <Paragraph>
          Mieliśmy przyjemność współpracować z&nbsp;wieloma klientami w&nbsp;Małopolsce. Naszymi usługami cieszyli się&nbsp;zarówno klienci indywidualni, jak i&nbsp;większe firmy.
          </Paragraph>
        </TextWrapper>
      </ContentWrapper>
    </QualityWrapper>
  )
}
