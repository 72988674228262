import  styled from 'styled-components'
import {OverflowWrapper, PageWrapper} from 'shared/Styles/Wrappers'
import { MediumHeader, Paragraph } from "components/Typography"

export const QualityWrapper = styled(OverflowWrapper)`
    height: 100vh;
    position:relative;
    @media screen and (max-width: ${({theme})=>theme.breakpoints.phoneBig}){
        height: unset;
    }
    @media screen and (max-width: ${({theme})=>theme.breakpoints.phoneBig}){
        margin-top: 60px;
        margin-bottom: 60px;
    }
`
export const BlueBackground = styled.div`
    position: absolute;
    width: 45.5vw;
    height: 100%;
    left: 0;
    top: 0;
    background: ${({theme})=>theme.color.primary};
    @media screen and (max-width: ${({theme})=>theme.breakpoints.phoneBig}){
        height: 320px;    
    }
`
export const ContentWrapper = styled(PageWrapper)`
    display: flex;
    align-items:center;
    justify-content:center;
    height: 100%;
    width: 100%;
    @media screen and (max-width: ${({theme})=>theme.breakpoints.phoneBig}){
        flex-direction: column;
    }
`
export const ImageWrapper = styled.div`
    width: 60%;
    flex-grow: 1;
    flex-shrink: 0;
    .gatsby-image-wrapper{
        width: 100%;
        height: 100%;
    }
    @media screen and (max-width: ${({theme})=>theme.breakpoints.tabletBig}){
        height: 500px;
    }
    @media screen and (max-width: ${({theme})=>theme.breakpoints.tabletSmall}){
        width: auto;
    }
    @media screen and (max-width: ${({theme})=>theme.breakpoints.phoneBig}){
        width: 100%;
        height: 260px;
        flex-grow: unset;
        margin-top: 30px;
    }
`
export const TextWrapper = styled.div`
    margin-left: 70px;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 0;
    ${MediumHeader}{
        margin-bottom: 20px;
    }
    ${Paragraph}{
        margin-bottom: 50px;
    }
    @media screen and (max-width: ${({theme})=>theme.breakpoints.tabletBig}){
        margin-left: 30px;
        ${MediumHeader}, ${Paragraph}{
            margin-bottom: 16px;
        }   
    }
    @media screen and (max-width: ${({theme})=>theme.breakpoints.tabletSmall}){
        width: 278px;
    }
    @media screen and (max-width: ${({theme})=>theme.breakpoints.phoneBig}){
        width: unset;
        padding: 0 30px;
        margin-left: 0;
        margin-top: 60px;
    }
    @media screen and (max-width: ${({theme})=>theme.breakpoints.phoneSmall}){
        padding: 0 10px;
    }
`
