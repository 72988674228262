import { graphql, useStaticQuery } from "gatsby"

export const useCertificateImages = () => {
  const images = useStaticQuery(graphql`
    query {
      slide_1: file(
        relativePath: { eq: "images/About/certyfikat_dla_przedsiebiorcow.jpg" }
      ) {
        childImageSharp {
          fixed(width: 400, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      slide_2: file(
        relativePath: { eq: "images/About/certyfikat_samsung.jpg" }
      ) {
        childImageSharp {
          fixed(width: 400, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      slide_3: file(relativePath: { eq: "images/About/certyfikat_AUX.jpg" }) {
        childImageSharp {
          fixed(width: 400, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      slide_4: file(relativePath: { eq: "images/About/certyfikat_Haier.jpg" }) {
        childImageSharp {
          fixed(width: 400, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      slide_5: file(relativePath: { eq: "images/About/certyfikat_Sevra.jpg" }) {
        childImageSharp {
          fixed(width: 400, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      slide_6: file(relativePath: { eq: "images/About/toshiba.jpg" }) {
        childImageSharp {
          fixed(width: 400, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      slide_7: file(
        relativePath: { eq: "images/About/certyfikat_Rotenso.jpg" }
      ) {
        childImageSharp {
          fixed(width: 400, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      slide_8: file(relativePath: { eq: "images/About/gree.jpg" }) {
        childImageSharp {
          fixed(width: 400, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return images
}
