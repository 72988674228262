import React from "react"

import { CardContainer, ImageWrapper, TextWrapper } from "./styles"
import { Paragraph } from "components/Typography"

const CertificateCard = ({ image, title, desc }) => {
  return (
    <CardContainer>
      <ImageWrapper>{image}</ImageWrapper>
      <TextWrapper>
        <Paragraph bolded={true} color="#1371FB">
          {title}
        </Paragraph>
        <Paragraph color="#000">{desc}</Paragraph>
      </TextWrapper>
    </CardContainer>
  )
}

export { CertificateCard }
