import React from "react"
import {
  Container,
  Wrapper,
  ImageBox,
  TextWrapper,
  TextBox,
  Title,
  Text,
} from "./styles"

import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

const data_about_header = [
  {
    title: "Kim jesteśmy",
    text: (
      <Text>
        Od kilku lat zajmujemy się montażem i&nbsp;serwisem klimatyzacji zarówno
        w&nbsp;domu, jak i&nbsp;w&nbsp;powierzchniach biurowych czy lokalach
        publicznych. Naszym klientom oferujemy najlepsze rozwiązania. Działamy
        na&nbsp;terenie całej Małopolski.
      </Text>
    ),
  },
  {
    title: "O firmie",
    text: (
      <Text>
        Specjaliści Klimalab działają w&nbsp;branży od&nbsp;2009 roku. Wieloletnie
        doświadczenie potwierdzają liczne certyfikaty. Nasi klienci otrzymają
        pomoc w&nbsp;wyborze klimatyzacji, montaż oraz serwis. Naszym priorytetem
        jest zadowolenie klientów, dlatego cenimy profesjonalizm oraz nowoczesne
        rozwiązania, które spełnią wszystkie potrzeby i&nbsp;oczekiwania.
      </Text>
    ),
  },
]

export const AboutHeader = () => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/About/about_main.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container>
      <ImageBox>
        <Image fluid={image.file.childImageSharp.fluid} />
      </ImageBox>
      <Wrapper>
        <TextWrapper>
          {data_about_header.map((data, i) => (
            <TextBox key={i}>
              <Title>{data.title}</Title>
              {data.text}
            </TextBox>
          ))}
        </TextWrapper>
      </Wrapper>
    </Container>
  )
}
