import React from "react"
import { MainSection } from "../sections/MainSection"
import { WhyUs } from "sections/About/WhyUs"
import { AboutHeader } from "sections/About/AboutHeader"
import { TestimonialsSlider } from "sections/About/TestimonialsSlider"
import { Certificates } from "sections/About/Certificates"
import { Quality } from "sections/About/Quality"
import SEO from "components/seo"
import { StyledOverflowWrapper } from "sections/About/WhyUs/styles"
const AboutUs = () => {
  return (
    <div>
      <SEO title="o firmie - wieloletnie doświadczenie w montażu i serwisie klimatyzacji" />
      <MainSection>
        <AboutHeader />
      </MainSection>
      <StyledOverflowWrapper>
        <Quality />
        <WhyUs />
      </StyledOverflowWrapper>
      <TestimonialsSlider />
      <Certificates />
    </div>
  )
}
export default AboutUs
