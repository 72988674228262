import React from "react"
import {
  CardContainer,
  ClientName,
  CompanyName,
  Testimony,
  QuoteTop,
  QuoteBottom,
} from "./styles"
import { Quote } from "components/Icons"

const TestimonialCard = ({ clientName, companyName, testimony }) => {
  return (
    <>
      <CardContainer>
        <QuoteTop>
          <Quote width="12px" height="16px" color="#1371FB" />
        </QuoteTop>
        <ClientName>{clientName}</ClientName>
        <CompanyName>{companyName}</CompanyName>
        <Testimony>{testimony}</Testimony>
        <QuoteBottom>
          <Quote width="12px" height="16px" color="#1371FB" />
        </QuoteBottom>
      </CardContainer>
    </>
  )
}
export { TestimonialCard }
