import React from "react"
import { CertificateCard } from "./CertificateCard"
import { PageWrapper } from "shared/Styles/Wrappers"
import {
  StyledSwiper,
  SliderContainer,
  HeaderContainer,
  ArrowLeftContainer,
  ArrowRightContainer,
} from "./styles"
import { SwiperSlide } from "swiper/react"
import { useCertificateData } from "shared/Hooks/About"
import { MediumHeader } from "components/Typography"
import { ArrowLong } from "components/Icons"

const Certificates = () => {
  const data = useCertificateData()
  return (
    <PageWrapper>
      <HeaderContainer>
        <MediumHeader>Certyfikaty</MediumHeader>
      </HeaderContainer>
      <SliderContainer>
        <ArrowLeftContainer className="certificate-prev">
          <ArrowLong width="41px" height="10px" color="#d7d7d7" />
        </ArrowLeftContainer>
        <StyledSwiper
          centeredSlides={false}
          slidesPerView={1}
          speed={800}
          loop={true}
          autoplay={{
            delay: 6000,
          }}
          navigation={{
            nextEl: ".certificate-next",
            prevEl: ".certificate-prev",
          }}
        >
          {data.map((certificate, index) => (
            <SwiperSlide key={index}>
              <CertificateCard
                image={certificate.image}
                title={certificate.title}
                desc={certificate.desc}
              />
            </SwiperSlide>
          ))}
        </StyledSwiper>
        <ArrowRightContainer className="certificate-next">
          <ArrowLong width="41px" height="10px" color="#d7d7d7" />
        </ArrowRightContainer>
      </SliderContainer>
    </PageWrapper>
  )
}

export { Certificates }
