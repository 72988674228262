import styled from "styled-components"
import { Paragraph } from "components/Typography"
import { IconPath } from "shared/Styles/Icons/icons"

import { Paragarph } from "components/Typography"

export const CardContainer = styled.div`
  position: relative;
  max-width: 340px;
  min-height: 390px;
  padding: 50px 40px 50px 40px;
  background-color: #fafafa;
  box-shadow: 10px 5px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 24px;
    bottom: 0;
    left: 0;
    transform: translateX(-105%);
    transition: all 0.5s ease;
    background: ${({ theme }) => theme.color.secondary};
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tabletSmall}) {
      height: 12px;
    }
  }
  &:hover::after {
    transform: translateX(0);
  }
`

export const ClientName = styled(Paragraph)`
  color: ${({ theme }) => theme.color.primary};
`
export const CompanyName = styled(Paragraph)`
  color: ${({ theme }) => theme.color.darkGray};
`

export const Testimony = styled(Paragraph)`
  padding-top: 50px;
  color: ${({ theme }) => theme.color.black};
`
export const QuoteTop = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
`
export const QuoteBottom = styled.div`
  position: absolute;
  bottom: 30px;
  left: 40px;
`
