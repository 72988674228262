import React from "react"
import { PageWrapper } from "shared/Styles/Wrappers"
import { useCompanyProsData } from "shared/Hooks/About"
import { MediumHeader } from "components/Typography"

import {
  StyledOverflowWrapper,
  SectionContainer,
  CardsWrapper,
  CardContainer,
  IconContainer,
  TitleContainer,
} from "./styles"

const WhyUs = () => {
  const data = useCompanyProsData()
  return (
    <>
        <PageWrapper>
          <SectionContainer>
            <MediumHeader>Dlaczego my</MediumHeader>
            <CardsWrapper>
              {data.map((card, index) => (
                <CardContainer key={index}>
                  <IconContainer>{card.icon}</IconContainer>
                  <TitleContainer>{card.title}</TitleContainer>
                  {card.desc}
                </CardContainer>
              ))}
            </CardsWrapper>
          </SectionContainer>
        </PageWrapper>
    </>
  )
}

export { WhyUs }
