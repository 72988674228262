import React from "react"
import { Car, Experience, Valuation } from "components/Icons"
import { NormalHeader, Paragraph } from "components/Typography"

export const useCompanyProsData = () => {
  const data = [
    {
      icon: <Car width="125px" height="82px" color="#1371FB" />,
      title: (
        <NormalHeader>
          Profesjonalny
          <br />i szybki montaż
        </NormalHeader>
      ),
      desc: (
        <Paragraph>
          Specjaliści Klimalab zamontują Twoją klimatyzację szybko
          i&nbsp;sprawnie. Jesteśmy na&nbsp;bieżąco z&nbsp;innowacyjnymi
          technologiami, dlatego gwarantujemy profesjonalną usługę oraz
          najnowsze rozwiązania.
        </Paragraph>
      ),
    },
    {
      icon: <Experience width="87px" height="100px" color="#1371FB" />,
      title: (
        <NormalHeader>
          Wieloletnie
          <br />
          doświadczenie
        </NormalHeader>
      ),
      desc: (
        <Paragraph>
          Nasi specjaliści zajmują się montażem i&nbsp;serwisem klimatyzacji
          od&nbsp;wielu lat. Przez ten czas współpracowali z&nbsp;wieloma
          klientami, dzięki czemu potrafią dotrzeć do&nbsp;ich oczekiwań
          i&nbsp;kompleksowo wykonać usługę.
        </Paragraph>
      ),
    },
    {
      icon: <Valuation width="125px" height="110px" color="#1371FB" />,
      title: (
        <NormalHeader>
          Darmowa
          <br />
          wycena
        </NormalHeader>
      ),
      desc: (
        <Paragraph>
          Decydując się&nbsp;na&nbsp;współpracę z&nbsp;nami, otrzymasz darmową
          wycenę projektu. Powiedz nam, czego oczekujesz,
          a&nbsp;my&nbsp;przygotujemy dla&nbsp;Ciebie najlepszą ofertę.
        </Paragraph>
      ),
    },
  ]
  return data
}
