import React from "react"
import { PageWrapper } from "shared/Styles/Wrappers"
import { TestimonialCard } from "./TestimonialCard"
import { SwiperSlide } from "swiper/react"
import {
  StyledSwiper,
  StyledSlider,
  SliderHeader,
  StyledOverflowHeader,
  StyledOverflowSlider,
  ArrowLeftContainer,
  ArrowRightContainer,
  StyledPageWrapper,
} from "./styles"
import { MediumHeader } from "components/Typography"
import { ArrowLong } from "components/Icons"

import { useTestimonialsData } from "shared/Hooks/About"

const TestimonialsSlider = () => {
  const data = useTestimonialsData()
  return (
    <>
      <StyledOverflowHeader>
        <PageWrapper>
          <SliderHeader>
            <MediumHeader color="#fff">Mówią o nas</MediumHeader>
            <ArrowLeftContainer className="testimonial-prev">
              <ArrowLong width="41px" height="10px" color="#fff" />
            </ArrowLeftContainer>
            <ArrowRightContainer className="testimonial-next">
              <ArrowLong width="41px" height="10px" color="#fff" />
            </ArrowRightContainer>
          </SliderHeader>
        </PageWrapper>
      </StyledOverflowHeader>
      <StyledOverflowSlider>
        <StyledPageWrapper>
          <StyledSwiper
            centeredSlides={true}
            slidesPerView={3}
            spaceBetween={0}
            speed={800}
            loop={true}
            autoplay={{
              delay: 6000,
            }}
            navigation={{
              nextEl: ".testimonial-next",
              prevEl: ".testimonial-prev",
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                centeredSlides: true,
              },
              864: {
                slidesPerView: 2,
                centeredSlides: false,
              },
              1159: {
                slidesPerView: 3,
                centeredSlides: false,
              },
            }}
          >
            {data.map((card, index) => (
              <SwiperSlide key={index}>
                <StyledSlider>
                  <TestimonialCard
                    clientName={card.client}
                    companyName={card.company}
                    testimony={card.testimony}
                  />
                </StyledSlider>
              </SwiperSlide>
            ))}
          </StyledSwiper>
        </StyledPageWrapper>
      </StyledOverflowSlider>
    </>
  )
}
export { TestimonialsSlider }
