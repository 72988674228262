import styled, { css } from "styled-components"
import { Swiper } from "swiper/react"
import { IconPathStroke } from "shared/Styles/Icons"

const Arrow = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 8px;
  z-index: 3;
`
const StrokeColor = css`
  &:hover {
    ${IconPathStroke} {
      stroke: ${({ theme }) => theme.color.primary};
      transition: stroke 0.3s ease;
    }
  }
`

export const SliderContainer = styled.div`
  position: relative;
  padding: 100px 40px 0 40px;
  margin-bottom: 200px;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    padding-top: 50px;
    margin-bottom: 80px;

  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    padding: 50px 0 0 0;
    margin-bottom: 40px;
  }
`

export const StyledSwiper = styled(Swiper)`
  position: relative;
  width: 90%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    width: 100%;
  }
  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 80px 0 0 0;
`

export const ArrowLeftContainer = styled.div`
  ${Arrow}
  transform: rotate(180deg);
  left: 0;
  ${StrokeColor}
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints.tabletSmall}) {
    top:-38px; /* This value is caused by transform rotate position issue */
    left: unset;
    right: 40px;
  }
`
export const ArrowRightContainer = styled.div`
  ${Arrow}
  right: 0;
  ${StrokeColor}
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints.tabletSmall}) {
      top: -20px;
      right: 0;
    }
`
