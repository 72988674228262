import styled from "styled-components"

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  max-width: 900px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
`

export const ImageWrapper = styled.div`
  height: 100%;
  max-height: 550px;
  .gatsby-image-wrapper {
    min-width: 280px;
    max-height: 550px;
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.laptopStandard}) {
      max-height: 440px;
    }
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tabletSmall}) {
      height: 400px !important;
    }
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.phoneBig}) {
      height: 350px !important;
    }
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.phoneSmall}) {
      height: 280px !important;
    }
  }
`

export const TextWrapper = styled.div`
  height: 100%;
  max-width: 260px;
  margin-top: 30px;
`
