import styled from "styled-components"

import { PageWrapper } from "../../../shared/Styles/Wrappers/"
import { MediumHeader, Paragraph } from "components/Typography"

import Waves from "../../../assets/images/About/waves.png"

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url(${Waves});
  background-repeat: no-repeat;
  background-position: top left;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50px;
    left: 0;
    width: 60px;
    height: 270px;
    background-color: ${props => props.theme.color.secondary};
    z-index: 2;
    @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
      width: 30px;
      height: 180px;
    }
    @media screen and (max-width: ${props =>
        props.theme.breakpoints.tabletBig}) {
      display: none;
    }
  }
  @media screen and (max-width: 650px) {
    flex-direction: column;
    width: 100%;
  }
`

export const Wrapper = styled(PageWrapper)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  z-index: 3;
  display: flex;
  align-items: center;
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    align-items: flex-start;
    padding-top: 40px;
  }
  @media screen and (max-width: 769px) {
    padding-top: 15px;
  }
  @media screen and (max-width: 650px) {
    position: relative;
  }
`

export const ImageBox = styled.div`
  width: 54.5vw;
  height: 100%;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0%;
    width: 100%;
    height: 21px;
    background-color: ${props => props.theme.color.secondary};
    z-index: 2;
  }
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
  }
`

export const TextWrapper = styled.div`
  width: 320px;
  @media screen and (max-width: 769px) {
    width: 260px;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
  }
`
export const TextBox = styled.div`
  margin: 20px 0;
  display: inline-block;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    margin: 15px 0;
  }
  @media screen and (max-width: 769px) {
    margin: 10px 0;
  }
  @media screen and (max-width: 650px) {
    margin: 30px 0;
  }
`

export const Title = styled(MediumHeader)`
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    font-size: 24px;
  }
  @media screen and (max-width: 769px) {
    font-size: 22px;
  }
`

export const Text = styled(Paragraph)`
  margin-top: 30px;
  line-height: 1.5;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    margin-top: 15px;
    font-size: 14px;
  }
  @media screen and (max-width: 769px) {
    margin-top: 10px;
  }
`

// @media screen and (max-width: ${props => props.theme.breakpoints.laptopBig})
// @media screen and (max-width: ${props => props.theme.breakpoints.laptopStandard
