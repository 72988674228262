import React from "react"
import { useCertificateImages } from "./useCertificateImages"
import { StyledImage } from "sections/About/Certificates/styles"
import Image from "gatsby-image"

const useCertificateData = () => {
  const images = useCertificateImages()
  const data = [
    {
      image: (
        <Image
          imgStyle={{ objectFit: "contain" }}
          fixed={images.slide_1.childImageSharp.fixed}
        />
      ),
      title: "Certyfikat dla przedsiębiorców",
      desc:
        "Posiadamy uprawnienia do prowadzenia działalności w zakresie instalacji oraz naprawy, konserwacji lub serwisowania oraz likwidacji urządzeń chłodniczych , klimatyzacyjnych lub pomp ciepła.",
    },
    {
      image: (
        <Image
          imgStyle={{ objectFit: "contain" }}
          fixed={images.slide_2.childImageSharp.fixed}
        />
      ),
      title: "Certyfikat Samsung",
      desc: "Posiadamy autoryzację na montaż i serwis urządzeń RAC, CAC i FJM.",
    },
    {
      image: (
        <Image
          imgStyle={{ objectFit: "contain" }}
          fixed={images.slide_3.childImageSharp.fixed}
        />
      ),
      title: "Certyfikat AUX",
      desc:
        "Posiadamy uprawnienia do instalowania, uruchomienia i serwisowania urządzeń klimatyzacyjnych marki AUX z racji RAC, LCAC - Single i Multi Split.",
    },
    {
      image: (
        <Image
          imgStyle={{ objectFit: "contain" }}
          fixed={images.slide_4.childImageSharp.fixed}
        />
      ),
      title: "Certyfikat Haier",
      desc:
        "Jesteśmy Autoryzowanym Punktem Serwisowym urządzeń klimatyzacyjnych marki Haier.",
    },
    {
      image: (
        <Image
          imgStyle={{ objectFit: "contain" }}
          fixed={images.slide_5.childImageSharp.fixed}
        />
      ),
      title: "Certyfikat Sevra",
      desc:
        "Posiadamy uprawnienia do instalowania, uruchomienia i serwisowania urządzeń klimatyzacyjnych marki Sevra z racji RAC, LCAC, Multi Split.",
    },
    {
      image: (
        <Image
          imgStyle={{ objectFit: "contain" }}
          fixed={images.slide_6.childImageSharp.fixed}
        />
      ),
      title: "Certyfikat Toshiba",
      desc:
        "Posiadamy uprawnienia do instalowania, uruchomienia oraz serwisowania urządzeń klimatyzacyjnych marki TOSHIBA seria RAS - typu Split i Multi-Split.",
    },
    {
      image: (
        <Image
          imgStyle={{ objectFit: "contain" }}
          fixed={images.slide_7.childImageSharp.fixed}
        />
      ),
      title: "Certyfikat Rotenso",
      desc:
        "Posiadamy autoryzację w zakresie doboru, sprzedaży, instalacji oraz serwisu klimatyzatorów Rotenso.",
    },
    {
      image: (
        <Image
          imgStyle={{ objectFit: "contain" }}
          fixed={images.slide_8.childImageSharp.fixed}
        />
      ),
      title: "Certyfikat Gree",
      desc:
        "Jesteśmy Autoryzowanym Instalatorem urządzeń klimatyzacyjnych i chłodniczych produkcji GREE serii RAC, U-Match, Free Match oraz posiadamy uprawnienia do projektowania, sprzedaży, montażu, uruchamiania i serwisowania tych urządzeń.",
    },
  ]
  return data
}

export { useCertificateData }
