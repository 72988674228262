import styled, { css } from "styled-components"
import { Swiper } from "swiper/react"
import { OverflowWrapper } from "shared/Styles/Wrappers"
import { IconPathStroke } from "shared/Styles/Icons"
import Signet from "src/assets/icons/signet.svg"
import { PageWrapper } from "shared/Styles/Wrappers"
const StrokeColor = css`
  &:hover {
    ${IconPathStroke} {
      stroke: ${({ theme }) => theme.color.secondary};
      transition: stroke 0.3s ease;
    }
  }
`

export const StyledPageWrapper = styled(PageWrapper)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    height: 205px;
  }
`

export const StyledSwiper = styled(Swiper)`
  margin: 0 -30px;
  transform: translateY(-100px);
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    transform: translateY(-300px);
  }
`
export const StyledSlider = styled.div`
  padding: 30px 30px;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    display: flex;
    justify-content: center;
  }
`

export const StyledOverflowHeader = styled(OverflowWrapper)`
  position: relative;
  background-color: ${({ theme }) => theme.color.primary};
  height: 405px;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-image: url(${Signet});
  background-size: auto 150%;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    background-size: auto 100%;
    background-position: top right;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    align-items: flex-start;
    padding: 50px 0;
  }
`
export const StyledOverflowSlider = styled(OverflowWrapper)`
  background-color: ${({ theme }) => theme.color.white};
  overflow: visible;
`

export const SliderHeader = styled.div`
  display: flex;
  align-items: center;
`

export const ArrowLeftContainer = styled.div`
  margin: 0 20px 0 60px;
  transform: rotate(180deg);
  cursor: pointer;
  margin-bottom: 3px; /*This margin fixes position issue caused by transform: rotate */
  ${StrokeColor}
`
export const ArrowRightContainer = styled.div`
  cursor: pointer;
  ${StrokeColor}
`
